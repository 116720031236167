import React, { useEffect, useState } from 'react';
import { getDistance } from 'geolib';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@progress/kendo-react-buttons';
import { Modal } from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';
/* components */
import { setReduxLocation, clearLocation } from 'state/actions';
import snapshotToArray from 'common/snapshotToArray';
import StringHelper from 'common/stringHelper';
import RegisterView from './Register.view';
import style from './_Register.module.scss';

let watchID;
let geolocationTimeout;

function RegisterContainer(props) {
  /* redux state */
  const state = useSelector(state => ({
    location: state.location,
  }));

  const dispatch = useDispatch();

  const [ locationList, setLocationList ] = useState([]);
  const [ geolocationFailed, setGeolocationFailed ] = useState(false);
  const [ permissionDenied, setPermissionDenied ] = useState(false);
  const [ locationLookupError, setLocationLookupError ] = useState(false);
  const [ showDebugInfo, setShowDebugInfo ] = useState(false);
  const [ locationID, setLocationID ] = useState('');
  const [ panelBarExpanded, setPanelBarExpanded ] = useState(false);
  const [ coords, setCoords ] = useState({
    // lat: 39.7508608,
    // long: -104.97556480000001,
    lat: null,
    long: null,
    accuracy: null,
  });
  const [ loading, setLoading ] = useState(false);
  const locationCollection = window.firebase.firestore().collection(`locations`);

  useEffect(() => {
    const unsubscribe = window.firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        window.firebase.firestore().doc(`metadata/${user.uid}`)
          .get()
          .then((doc) => {
            const metadata = doc.data();
            if (metadata && metadata.location_id) {
              window.firebase.firestore().doc(`queues/${metadata.location_id}/guests/${user.uid}`)
                .get()
                .then((doc) => {
                  const guest = doc.data();

                  if (guest) {
                    // use location_id to get location and brand details, save location to redux state
                    window.firebase.firestore().doc(`locations/${metadata.location_id}`)
                      .get()
                      .then((doc) => {
                        const location = doc.data();

                        dispatch(setReduxLocation(location));
                      });
                    // unsubscribe redirect to waiting page
                    unsubscribe();
                    props.history.push(`/waiting/${metadata.location_id}/${user.uid}`);
                  }
                  else {
                    unsubscribe();
                    dispatch(clearLocation());
                    window.firebase.auth().signOut();
                  }
                });
            } else {
              unsubscribe();
              dispatch(clearLocation());
              window.firebase.auth().signOut();
            }
          });
      }
    });

    // fetch all stores
    const locationUnsubscribe = locationCollection.onSnapshot((snapshot) => {
      setLocationList(
        snapshotToArray(snapshot)
      );
    });

    // check for Geolocation support
    if (navigator.geolocation) {
      handlePermission()
        .then(() => {
          getLocation();
        })
        .catch(() => {
          console.log('geoLocation permissions error: access not granted');
        });
    }
    else {
      console.log('Geolocation is not supported for this Browser/OS.');
    }

    return () => {
      locationUnsubscribe();
      unsubscribe();
      navigator.geolocation.clearWatch(watchID);
    };
  }, [ ]);

  /* stop running the getLocation function once coords are updated */
  useEffect(() => {
    navigator.geolocation.clearWatch(watchID);
    clearTimeout(geolocationTimeout);
  }, [ coords ]);

  function getLocation() {
    console.log('getting location');
    const geoSuccess = function(position) {
      console.log('got position, geolocation object:', position);
      navigator.geolocation.clearWatch(watchID);
      return setCoords({
        ...coords,
        lat: position.coords.latitude,
        long: position.coords.longitude,
        accuracy: position.coords.accuracy,
      });
    };

    const geoError = function(error) {
      console.log('geoLocation error: ', error);
      if (error.PERMISSION_DENIED) {
        setPermissionDenied(true);
        navigator.geolocation.clearWatch(watchID);
      }
      return setGeolocationFailed(true);
    };

    setGeolocationFailed(false);
    navigator.geolocation.clearWatch(watchID);

    watchID = navigator.geolocation.watchPosition(geoSuccess, geoError, { enableHighAccuracy: true, maximumAge: 100 });
    geolocationTimeout = setTimeout(() => {
      console.log('geolocationTimeout');
      navigator.geolocation.clearWatch(watchID);
      return setGeolocationFailed(true);
    }, 10000);
  }

  function getFilteredLocations() {
    console.log('getFilteredLocations coords', coords);
    console.log('getFilteredLocations locationList', locationList);
    return locationList.filter((loc) => {
      if (!coords.lat || !loc.latitude) return false;

      const distance = loc.distance || 1000; // distance is in meters. prefer location custom distance over default 1000
      const distanceFromLocation = getDistance({
        latitude: coords.lat,
        longitude: coords.long,
      }, {
        latitude: loc.latitude,
        longitude: loc.longitude,
      });
      console.log('accuracy', coords.accuracy);
      console.log('distanceFromLocation', loc.name, distanceFromLocation - coords.accuracy);
      if (distanceFromLocation - coords.accuracy <= distance) {
        return loc;
      }
    });
  }

  function goToJoin(location) {
    console.log('goToJoin', location);
    dispatch(setReduxLocation(location));
    const navigationInterval = setInterval(() => {
      console.log('goToJoin interval', state.location.brandDetails);
      if (state.location.brandDetails) {
        clearInterval(navigationInterval);
        return props.history.push(`/join/${location.location_id}`);
      }
    }, 300);
  }

  function handlePermission() {
    /* Safari doesn't support navigator.permissions */
    if (!navigator.permissions) return Promise.resolve();

    return navigator.permissions.query({ name: 'geolocation' })
      .then((result) => {
        if (result.state === 'granted') {
          setPermissionDenied(false);
          console.log(result.state);
          return Promise.resolve();
        } else if (result.state === 'prompt') {
          console.log(result.state);
        } else if (result.state === 'denied') {
          console.log(result.state);
          setPermissionDenied(true);
          return setGeolocationFailed(true);
        }

        result.onchange = function() {
          console.log('onChange', result.state);
          if (result.state === 'granted') {
            return setPermissionDenied(false);
          }
        };
      });
  }

  function handleDebugDetails(value) {
    setShowDebugInfo(value);
  }

  function handleSetValue(e) {
    setLocationLookupError(false);
    return setLocationID(e.value);
  }

  function useLocationID(id) {
    /* firebase will silently fail if we pass an invalid string as a location id. check regex before searching for it */
    if (!id.match(/^(\d|\w)+$/)) {
      return setLocationLookupError(true);
    }
    
    // window.firebase.firestore().doc(`locations/${id}`)
    //   .get()
    //   .then((doc) => {
    //     if (doc.exists) {
    //       setLocationLookupError(false);
    //       goToJoin(doc.data());
    //     }
    //     else {
    //       setLocationLookupError(true);
    //     }
    //   });
    window.firebase.firestore().collection('locations')
      .get()
      .then((snapshot) => {
        for (const doc of snapshot.docs) {
          if (doc.id.toLowerCase() === id.toLowerCase()) {
            setLocationLookupError(false);
            goToJoin(doc.data());
            return;
          }
          setLocationLookupError(true);
        }
      });
  }

  function handlePanelBar(value) {
    return setPanelBarExpanded(value);
  }

  return (
    <div className={style.containerWrapper} style={{ minHeight: window.innerHeight, overflow: scroll }}>
      <RegisterView
        locationList={getFilteredLocations()}
        // locationList={locationList}
        goToJoin={goToJoin}
        coords={coords}
        geolocationFailed={geolocationFailed}
        handleDebugDetails={handleDebugDetails}
        showDebugInfo={showDebugInfo}
        locationID={locationID}
        handleSetValue={handleSetValue}
        useLocationID={useLocationID}
        permissionDenied={permissionDenied}
        locationLookupError={locationLookupError}
        panelBarExpanded={panelBarExpanded}
        handlePanelBar={handlePanelBar}
        stringHelper={new StringHelper('en')}
        loading={loading}
      />

      <Modal
        title="Debugging Info"
        visible={showDebugInfo}
        onOk={() => handleDebugDetails(false)}
        onCancel={() => handleDebugDetails(false)}
      >
        <div>
          {
            geolocationFailed
              ? 'Geolocation failed, could not get your location coordinates'
              :
              <div>
                Your Location: {coords.lat}, {coords.long}<br />
                Accuracy: {coords.accuracy} meters<br />
                <br />
                Filtered Locations ({locationList.length}):
                {locationList.map((loc) => {
                  if (!coords.lat || !loc.latitude) return false;

                  const distanceFromLocation = getDistance({
                    latitude: coords.lat,
                    longitude: coords.long,
                  }, {
                    latitude: loc.latitude,
                    longitude: loc.longitude,
                  });

                  return (
                    <p key={loc.key}>
                      {loc.name}<br />
                      Distance from location: {distanceFromLocation - coords.accuracy} meters
                    </p>
                  );
                })}
              </div>
          }

        </div>
      </Modal>
      {/* <a className={style.promo_card} href={'https://joinourline.com/signup'}>
        <h3 className={style.promo_text}>Are you a business and would like to sign up for Join Our Line? <br /> <p style={{ color: '#129A7F', margin: 0 }}>Click here!</p></h3>
      </a> */}
      <div className={style.footerContent}>
        <p>Businesses:<br/> click <a href={`http://about.joinourline.com`} target='_blank' className={`ld_cursor_pointer`}>
          here
        </a> to learn more</p>
        <Button
          style={{ display: 'none', position: 'absolute', bottom: 0, left: 0, background: 'black', border: '1px solid darkslategray' }}
          onClick={() => { handleDebugDetails(true); }}
        />

        <div className={style.privacy_footer_content}>
          &copy; {moment().year()} Latitude Digital&nbsp;&nbsp;|&nbsp;&nbsp;<Link to={'/privacy'} target={'_blank'} className={`ld_cursor_pointer`}>Privacy Policy</Link>
        </div>

      </div>
    </div>
  );
}

export default RegisterContainer;
