/* eslint-disable id-length */
import React from 'react';

import mergeWith from 'lodash/mergeWith';

const mergeNonNull = (objValue, srcValue) => srcValue === null ? objValue : undefined;

class StringHelper {
  constructor(language, location, brand) {
    this.language = language;
    this.stringValues = mergeWith(privateStrings, defaultStrings, brand, location, mergeNonNull);
  }

  createString = (key, ...params) => {
    const stringObject = this.stringValues[key] || {};
    let localizedString = stringObject[this.language];

    if (!localizedString) {
      localizedString = stringObject['en'];
    }

    if (!localizedString) {
      localizedString = `***${key}***`;
    }

    return localizedString.replace(/{(\d+)}/g, (match, number) => {
      return typeof params[number] != 'undefined'
        ? params[number]
        : match;
    });
  };

  createDivWithString = (divProps, key, ...params) => {
    const stringObject = this.stringValues[key] || {};
    let localizedString = stringObject[this.language];

    if (!localizedString) {
      localizedString = stringObject['en'];
    }

    if (!localizedString) {
      localizedString = `***${key}***`;
    }

    const finalString = localizedString.replace(/{(\d+)}/g, (match, number) => {
      return typeof params[number] != 'undefined'
        ? params[number]
        : match;
    })

    return React.createElement('div', {
      ...divProps,
      dangerouslySetInnerHTML: { __html: finalString },
    });
  }

  getDefaultStrings = () => {
    return defaultStrings;
  }
}

const privateStrings = {
  'join-our-line': {
    key: 'join-our-line',
    'en': 'Join Our Line',
  },
  'connection-error': {
    key: 'connection-error',
    'en': 'Could not connect to server, please try again later.',
  },
  'register-use-mobile': {
    key: 'register-use-mobile',
    en: `Please use a mobile device.`,
  },
  'gps-mobile-ios': {
    key: 'gps-mobile-ios',
    en: `
      <ol>
        <li>On the Home screen, tap <b>Settings</b></li>
        <li>Tap <b>Privacy</b></li>
        <li>Tap <b>Location Services</b></li>
        <li>Ensure the <b>Location Services</b> switch is set to on</li>
        <li>Select your <b>browser</b> from the list</li>
        <li>Under <b>Allow Location Access</b>, select Ask Next Time or While Using the App</li>
      </ol>    
    `,
  },
  'gps-mobile-android': {
    key: 'gps-mobile-android',
    en: `
        <ol>
          <li>Go to Apps > <b>Settings</b></li>
          <li>If available, tap <b>Location</b></li>
          <li>Ensure the <b>Location</b> switch is set to on</li>
          <li>Tap <b>Mode</b> or <b>Locating method</b> then select one of the following:</li>
          <ul>
            <li>High accuracy{/* : Uses GPS, Wi-Fi, and mobile networks to estimate location */}</li>
            <li>Power saving/Battery saving{/* : Uses Wi-Fi and mobile networks to estimate location */}</li>
          </ul>
          <li>If presented with a Location consent prompt, tap <b>Agree</b></li>
        </ol>   
    `,
  },
  'gps-mobile-windows': {
    key: 'gps-mobile-windows',
    en: `<p>Please make sure your GPS is enabled and your privacy settings are not preventing location services</p>`,
  },
  'gps-permission-denied': {
    key: 'gps-permission-denied',
    en: `
            We cannot determine your location through GPS. <br/><br/>
            Please <b>update your Location Settings</b>, <b>look up your location by ID</b>, or <b>see a door attendant</b> to be added to the line manually.
    `,
  },
  'gps-settings-header': {
    key: 'gps-settings-header',
    en: `To update your Location Settings`,
  },
  'register-location-by-id': {
    key: 'register-location-by-id',
    en: `Look up your location by ID:`,
  },
  'register-no-location-found': {
    key: 'register-no-location-found',
    en: `No Location Found. Check the ID and try again.`,
  },
  'location-id': {
    key: 'location-id',
    en: `Location ID`,
  },
  'find-location': {
    key: 'find-location',
    en: `Find Location`,
  },
  'gps-error': {
    key: 'gps-error',
    en: `
      We cannot determine your location through GPS. <br/><br/>
      Please refresh the page to try again, or see a door attendant to be added to the line manually.<br/><br/>
    `,
  },
  'refresh-page': {
    key: 'refresh-page',
    en: `Refresh Page`,
  },
  'gps-browser-error': {
    key: 'gps-browser-error',
    en: `Your browser does not support GeoLocation. Please open this site in a different browser so we can help you find a location close to you.`,
  },
  'register-browser-permission': {
    key: 'register-browser-permission',
    en: `Your browser may ask you to use your current location, you <b>MUST click “Allow”</b> for us to confirm you are at the location.`,
  },
  'register-finding-location': {
    key: 'register-finding-location',
    en: `Please wait, we're finding your location`,
  },
  'register-no-locations': {
    key: 'register-no-locations',
    en: `
      <p>You must be at a participating location in order to join the line. <br />
      Please move closer to a participating location.</p>
    `,
  },
  'register-select-location': {
    key: 'register-select-location',
    en: `Please select a location:`,
  },
};

const defaultStrings = {
  'join-time-error': {
    key: 'join-time-error',
    name: 'Join Time Error',
    'en': 'You can only join the line between {0} and {1}.<br /> Please check back during those times.',
  },
  'join-line-full-error': {
    key: 'join-line-full-error',
    name: 'Join Line Full Error',
    'en': 'The line for this location is full right now.<br /> Stay on this page, and we\'ll let you know when you can join!',
  },
  'join-party-waiting-singular': {
    key: 'join-party-waiting-singular',
    name: 'Join Screen - Number of people waiting (singular)',
    'en': 'There is <b>{0}</b> person waiting.',
  },
  'join-party-waiting-plural': {
    key: 'join-party-waiting-plural',
    name: 'Join Screen - Number of people waiting (plural)',
    'en': 'There are <b>{0}</b> people waiting.',
  },
  'join-name-input': {
    key: 'join-name-input',
    name: 'Join Screen - Name input field',
    'en': 'Name:',
  },
  'join-name-error': {
    key: 'join-name-error',
    name: 'Join Screen - Name error',
    'en': 'Name is required',
  },
  'join-phone-input': {
    key: 'join-phone-input',
    name: 'Join Screen - Mobile Phone (for SMS) input field',
    'en': "Mobile Phone number so we can text you when you're next:",
  },
  'join-phone-error': {
    key: 'join-phone-error',
    name: 'Join Screen - Phone error',
    'en': 'Mobile Phone number is required',
  },
  'join-email-input': {
    key: 'join-email-input',
    name: 'Join Screen - Email input field',
    'en': 'Email:',
  },
  'join-email-error': {
    key: 'join-email-error',
    name: 'Join Screen - Email error',
    'en': 'Email is required',
  },
  'join-guests-input': {
    key: 'join-guests-input',
    name: 'Join Screen - Number of Guests selector',
    'en': 'Number of guests in your party:',
  },
  'join-button': {
    key: 'join-button',
    name: 'Join Screen - Join button',
    'en': 'Join the Line',
  },
  'waiting-instructions': {
    key: 'waiting-instructions',
    name: 'Waiting Instructions',
    'en': 'Please wait outside of location or in your vehicle.',
  },
  'waiting-instructions-details': {
    key: 'waiting-instructions-details',
    name: 'Waiting Instructions details',
    'en': 'No need to refresh this page, it will update automatically.',
  },
  'waiting-place-in-line': {
    key: 'waiting-place-in-line',
    name: 'Waiting Place In Line',
    'en': 'You are {0} in line.',
  },
  'waiting-priority-disclaimer': {
    key: 'waiting-priority-disclaimer',
    name: 'Waiting Priority Disclaimer',
    'en': 'Your place in line may increase if priority guests, such as seniors, join our line after you.',
  },
  'waiting-social-distancing': {
    key: 'waiting-social-distancing',
    name: 'Waiting Social Distancing / Promotion',
    input: 'wysiwyg',
    'en': '<h2>Social Distancing Guidelines</h2>\n<ul>\n	<li>Do not enter if you\'re showing flu-like symptoms: fever, cough, or shortness of breath.</li>\n	<li>Maintain a distance of at least 6 feet when possible.</li>\n	<li>Frequently clean hands with soap and hot water for at least 20 seconds. Use alcohol-based hand-sanitizer (over 60% alcohol) when needed. Disinfect contact areas frequently.</li>\n	<li>Avoid any direct physical contact and maintain a distance of at least 6 feet when possible.</li>\n</ul>\n',
  },
  'checkin-expired': {
    key: 'checkin-expired',
    name: 'Checkin expired message',
    'en': 'Sorry, you missed your check in time. Please see an attendant or click here to start over.',
  },
  'checkin-instructions-header': {
    key: 'checkin-instructions-header',
    name: 'Checkin Instructions Header',
    'en': 'It\'s your turn!',
  },
  'checkin-instructions': {
    key: 'checkin-instructions',
    name: 'Checkin Instructions',
    'en': 'Please show your device to the door attendant.',
  },
  'checkin-expire-instructions': {
    key: 'checkin-expire-instructions',
    name: 'Checkin Instructions Expiration Notice',
    'en': 'Please check in by {0} or your place in line will be lost.',
  },
  'checkin-social-distancing': {
    key: 'checkin-social-distancing',
    name: 'Checkin Social Distancing / Promotion',
    input: 'wysiwyg',
    'en': '<h2>Social Distancing Guidelines</h2>\n<ul>\n	<li>Do not enter if you\'re showing flu-like symptoms: fever, cough, or shortness of breath.</li>\n	<li>Maintain a distance of at least 6 feet when possible.</li>\n	<li>Frequently clean hands with soap and hot water for at least 20 seconds. Use alcohol-based hand-sanitizer (over 60% alcohol) when needed. Disinfect contact areas frequently.</li>\n	<li>Avoid any direct physical contact and maintain a distance of at least 6 feet when possible.</li>\n</ul>\n',
  },
  'covid-failed-message': {
    key: 'covid-failed-message',
    name: 'Positive Covid Screen Message',
    'en': 'You are not permitted to join the line, please see the door attendant if you have an extenuating circumstance.',
  },
};

export default StringHelper;

